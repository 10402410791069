import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AuthLayout from 'layout/authLayout/index';
import { isSignedIn } from 'utils/constantFun';
import LazyLoading from 'component/lazyLoading';
import DashBoradLayout from 'layout/dashBoardLayout';

const ComingSoon = lazy(() => import('../pages/comingSoon'));

// landing page layout
const LandingPage = lazy(() => import('../pages/landingPage'));
const LandinPageLayout = lazy(() => import('../layout/landingPageLayout'));
const WealthManagment = lazy(
  () => import('../pages/landingPage/sections/services/wealthManagment')
);
const DirectDepositFeature = lazy(
  () => import('../pages/landingPage/sections/services/subComponent/directDepositFeature')
);
const NetWorthService = lazy(
  () => import('../pages/landingPage/sections/services/subComponent/netWorthService')
);
const WealthManagmentService = lazy(
  () => import('../pages/landingPage/sections/services/subComponent/wealthManagmentService')
);
const MasterCardFeature = lazy(
  () => import('../pages/landingPage/sections/services/subComponent/masterCardFeature')
);
const Rewards = lazy(() => import('../pages/landingPage/sections/services/subComponent/rewards'));
const MoneyTransferService = lazy(
  () => import('../pages/landingPage/sections/services/subComponent/moneyTransferService')
);
const NetWorth = lazy(() => import('../pages/landingPage/sections/services/netWorth'));
const TechSupport = lazy(() => import('../pages/landingPage/contact'));
const AboutUS = lazy(() => import('../pages/landingPage/sections/aboutUs'));
const RewardsPage = lazy(() => import('../pages/landingPage/disclaimer/rewards'));
const FAQPage = lazy(() => import('../pages/landingPage/faq'));
const MediaPage = lazy(() => import('../pages/landingPage/pressRelease'));
const PressDetail = lazy(() => import('../pages/landingPage/pressRelease/section/pressDetail'));
const PrivacyAndSecurity = lazy(() => import('../pages/landingPage/privacyAndSecurity'));
const TermsAndCondition = lazy(() => import('../pages/landingPage/termsAndCondition'));
const DownloadApp = lazy(() => import('../pages/downloadApp/index'));
const PrivacyPolicies = lazy(() => import('../pages/landingPage/disclaimer/privacyPolicy'));
const Disclaimer = lazy(() => import('../pages/landingPage/disclaimer'));
const ProgramFees = lazy(() => import('../pages/landingPage/programFees'));

// dashboard layout
const DashBoard = lazy(() => import('../pages/customerPortal/dashBoard'));
const AssetsAnalytics = lazy(
  () => import('../pages/customerPortal/dashBoard/section/assetsAnalytics')
);
const LiabilitiesAnalytics = lazy(
  () => import('../pages/customerPortal/dashBoard/section/liabilitiesAnalytics')
);
const Cards = lazy(() => import('../pages/customerPortal/cards'));
const OrderCard = lazy(() => import('../pages/customerPortal/cards/subComponent/orderCard'));
const Setting = lazy(() => import('../pages/customerPortal/setting'));
const DocumentsListing = lazy(
  () => import('../pages/customerPortal/setting/subComponent/documentsListing')
);

const UserNetWorth = lazy(() => import('../pages/customerPortal/networth'));
const Userprofile = lazy(() => import('../pages/customerPortal/profile'));
const Assets = lazy(() => import('../pages/customerPortal/networth/assets/assets'));
const Liabilities = lazy(() => import('../pages/customerPortal/networth/liabilities/liabilities'));
const AddGoals = lazy(() => import('../pages/customerPortal/networth/setGoals/addGoals'));
const GoalDetail = lazy(() => import('../pages/customerPortal/networth/setGoals/goalDetail'));
const RealEstate = lazy(() => import('../pages/customerPortal/networth/assets/realEstate'));
const Other = lazy(() => import('../pages/customerPortal/networth/assets/otherAsset'));
const SetGoals = lazy(() => import('../pages/customerPortal/networth/setGoals'));
const OtherLiabilitiesDetail = lazy(
  () => import('../pages/customerPortal/networth/liabilities/ohterLiabilitiesDetail')
);
const AssetDetails = lazy(() => import('../pages/customerPortal/networth/assets/assetDetails'));
const LiabilitiesDetails = lazy(
  () => import('../pages/customerPortal/networth/liabilities/LiabilitiesDetails')
);
const Support = lazy(() => import('../pages/customerPortal/support'));
const Notification = lazy(() => import('../pages/customerPortal/notification'));
const Investment = lazy(() => import('../pages/customerPortal/networth/assets/investment'));
const Loan = lazy(() => import('../pages/customerPortal/networth/liabilities/loan'));
const FinanceExpert = lazy(() => import('../pages/customerPortal/networth/financeExpert'));
const WealthInsight = lazy(() => import('../pages/customerPortal/wealthInsight'));
const DebtAssetRation = lazy(() => import('../pages/customerPortal/networth/debtAssetRatio'));
const Insurance = lazy(() => import('../pages/customerPortal/networth/assets/insurance'));
const TermLifeInsurance = lazy(
  () => import('../pages/customerPortal/networth/assets/insurance/subComponent/termLifeInsurance')
);
const UmbrellaInsurance = lazy(
  () => import('../pages/customerPortal/networth/assets/insurance/subComponent/umbrellaInsurance')
);
const WholeLifeInsurance = lazy(
  () => import('../pages/customerPortal/networth/assets/insurance/subComponent/wholeLifeInsurance')
);
const LongTermInsurance = lazy(
  () => import('../pages/customerPortal/networth/assets/insurance/subComponent/longTermInsurance')
);
const AutoInsurance = lazy(
  () => import('../pages/customerPortal/networth/assets/insurance/subComponent/autoInsurance')
);
const DisabilityInsurance = lazy(
  () => import('../pages/customerPortal/networth/assets/insurance/subComponent/disabilityInsurance')
);
const EstatePlanning = lazy(() => import('../pages/customerPortal/networth/estatePlanning'));
const EstatePowerOfAttorney = lazy(
  () => import('../pages/customerPortal/networth/estatePlanning/powerOfAttorney/index')
);
const AddPowerOfAttorney = lazy(
  () => import('../pages/customerPortal/networth/estatePlanning/powerOfAttorney/addPowerOfAttorney')
);
const EstateHippaRelease = lazy(
  () => import('../pages/customerPortal/networth/estatePlanning/hipaaRelease')
);
const AddHippaRelease = lazy(
  () => import('../pages/customerPortal/networth/estatePlanning/hipaaRelease/addHipaaRelease')
);
const EstateLivingWill = lazy(
  () => import('../pages/customerPortal/networth/estatePlanning/livingWill')
);
const AddLivingWill = lazy(
  () => import('../pages/customerPortal/networth/estatePlanning/livingWill/addLivingWill')
);
const EstateLastWill = lazy(
  () => import('../pages/customerPortal/networth/estatePlanning/lastWillAndTestament')
);
const AddLastWill = lazy(
  () => import('../pages/customerPortal/networth/estatePlanning/lastWillAndTestament/addLastWill')
);
const SendMoney = lazy(() => import('../pages/customerPortal/sendMoney/index'));
const ACHTransfer = lazy(
  () => import('../pages/customerPortal/sendMoney/subComponent/achTransfer/index')
);
const PayeeTransfer = lazy(
  () => import('../pages/customerPortal/sendMoney/subComponent/payeeTransfer/index')
);
const ConnecAccountTransfer = lazy(
  () => import('../pages/customerPortal/sendMoney/subComponent/connectedAccountTransfer/index')
);
const BalanceHistory = lazy(() => import('../pages/customerPortal/balanceHistory/index'));

// auth layout
const Login = lazy(() => import('../pages/customerPortal/auth/login/index'));
const SignUp = lazy(() => import('../pages/customerPortal/auth/signUp'));
const ForgotPassWord = lazy(() => import('../pages/customerPortal/auth/forgot'));
const Otp = lazy(() => import('../pages/customerPortal/auth/otp/otp'));

const HippaRelease = lazy(() => import('../pages/EstateSignForms/hippaRelease'));
const LivingWill = lazy(() => import('../pages/EstateSignForms/livingWill'));
const PowerOfAttorney = lazy(() => import('../pages/EstateSignForms/powerOfAttorney'));
const LastWill = lazy(() => import('../pages/EstateSignForms/lastWill'));
const AtmLocator = lazy(() => import('../pages/customerPortal/cards/subComponent/atmLocator'));
const CreditCardTranscations = lazy(
  () => import('../pages/customerPortal/cards/subComponent/creditCardTranscations/index')
);
const DownloadBankStatement = lazy(() => import('../pages/downloadBankStatement/index'));

// download bank letter

const DownloadBankLetter = lazy(() => import('../pages/downloadBankLetter/index'));

// reset password

const ResetPassword = lazy(() => import('../pages/ResetPassword/index'));

// MFA
const TwoStepAuthentication = lazy(
  () => import('../pages/customerPortal/auth/twoFactorAuthentication/index')
);
const MfaSetUp = lazy(
  () => import('../pages/customerPortal/auth/twoFactorAuthentication/mfaSetUp')
);
const MfaOtpScreen = lazy(() => import('../pages/customerPortal/auth/login/mfaOtpScreen'));
const EmailOtpScreen = lazy(() => import('../pages/customerPortal/auth/login/emailOtpScreen'));

// setting

const TwoFactorAuthenticationSetting = lazy(
  () => import('../pages/customerPortal/setting/subComponent/twoFactorAuthenticationSetting')
);
export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element:
        process.env.REACT_APP_MODE === 'production' ? (
          <Suspense fallback={<LazyLoading />}>
            <ComingSoon />
          </Suspense>
        ) : !isSignedIn ? (
          <Suspense fallback={<LazyLoading />}>
            <LandinPageLayout />
          </Suspense>
        ) : (
          <Navigate to="/customer/dashboard" />
        ),
      children: [
        {
          path: '/',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <LandingPage />
            </Suspense>
          )
        },
        {
          path: '/wealth-insights',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <WealthManagment />
            </Suspense>
          )
        },
        {
          path: '/services/mastercard-feature',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <MasterCardFeature />
            </Suspense>
          )
        },
        {
          path: '/services/direct-deposit-feature',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <DirectDepositFeature />
            </Suspense>
          )
        },
        {
          path: '/services/net-worth-service',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <NetWorthService />
            </Suspense>
          )
        },
        {
          path: '/services/wealth-management-service',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <WealthManagmentService />
            </Suspense>
          )
        },
        {
          path: '/services/rewards',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Rewards />
            </Suspense>
          )
        },
        {
          path: '/services/money-transfer-service',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <MoneyTransferService />
            </Suspense>
          )
        },
        {
          path: '/net-worth',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <NetWorth />
            </Suspense>
          )
        },
        {
          path: '/contact',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <TechSupport />
            </Suspense>
          )
        },
        {
          path: '/about-us',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AboutUS />
            </Suspense>
          )
        },
        {
          path: '/rewards',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <RewardsPage />
            </Suspense>
          )
        },
        {
          path: '/faq',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <FAQPage />
            </Suspense>
          )
        },
        {
          path: '/press-releases',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <MediaPage />
            </Suspense>
          )
        },
        {
          path: '/press-detail/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <PressDetail />
            </Suspense>
          )
        },
        {
          path: '/privacy-security',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <PrivacyAndSecurity />
            </Suspense>
          )
        },
        {
          path: '/silverback/terms-condition',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <TermsAndCondition />
            </Suspense>
          )
        },
        {
          path: '/terms-conditions',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <TermsAndCondition />
            </Suspense>
          )
        },

        {
          path: '/silverback/privacy-policy',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <PrivacyPolicies />
            </Suspense>
          )
        },
        {
          path: '/privacy-policies',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <PrivacyPolicies />
            </Suspense>
          )
        },
        {
          path: '/disclaimer',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Disclaimer />
            </Suspense>
          )
        },
        {
          path: '/program-fees',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <ProgramFees />
            </Suspense>
          )
        }
      ]
    },
    {
      path: '/customer',
      element: <DashBoradLayout />,
      children: [
        {
          path: 'privacy-policies',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <PrivacyPolicies />
            </Suspense>
          )
        },
        {
          path: 'dashboard',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <DashBoard />
            </Suspense>
          )
        },
        {
          path: 'dashboard/asset-analytic',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AssetsAnalytics />
            </Suspense>
          )
        },
        {
          path: 'dashboard/liability-analytic',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <LiabilitiesAnalytics />
            </Suspense>
          )
        },
        {
          path: 'cards',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Cards />
            </Suspense>
          )
        },
        {
          path: 'cards/cards-transcations/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <CreditCardTranscations />
            </Suspense>
          )
        },
        {
          path: 'cards/order-physical-card',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <OrderCard />
            </Suspense>
          )
        },
        {
          path: 'settings',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Setting />
            </Suspense>
          )
        },
        {
          path: 'settings/documents-listing',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <DocumentsListing />
            </Suspense>
          )
        },
        {
          path: 'settings/two-factor-authentication',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <TwoFactorAuthenticationSetting />
            </Suspense>
          )
        },
        {
          path: 'net-worth',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <UserNetWorth />
            </Suspense>
          )
        },
        {
          path: 'settings/profile',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Userprofile />
            </Suspense>
          )
        },
        {
          path: 'net-worth',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <UserNetWorth />
            </Suspense>
          )
        },
        {
          path: 'net-worth/assets',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Assets />
            </Suspense>
          )
        },
        {
          path: 'net-worth/liabilities',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Liabilities />
            </Suspense>
          )
        },
        {
          path: 'net-worth/goals',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <SetGoals />
            </Suspense>
          )
        },
        {
          path: 'net-worth/set-goal',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AddGoals />
            </Suspense>
          )
        },
        {
          path: 'net-worth/update-goals/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AddGoals />
            </Suspense>
          )
        },
        {
          path: 'net-worth/goal-detail/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <GoalDetail />
            </Suspense>
          )
        },
        {
          path: 'net-worth/real-estate',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <RealEstate />
            </Suspense>
          )
        },
        {
          path: 'net-worth/other',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Other />
            </Suspense>
          )
        },
        {
          path: 'otherLiabilities',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <OtherLiabilitiesDetail />
            </Suspense>
          )
        },

        {
          path: '/customer/net-worth/asset-detail/:type',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AssetDetails />
            </Suspense>
          )
        },
        {
          path: '/customer/net-worth/liabilities-detail/:type',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <LiabilitiesDetails />
            </Suspense>
          )
        },
        {
          path: 'support',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Support />
            </Suspense>
          )
        },
        {
          path: 'wealth-insight',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <WealthInsight />
            </Suspense>
          )
        },
        {
          path: 'notification',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Notification />
            </Suspense>
          )
        },
        {
          path: 'net-worth/investment',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Investment />
            </Suspense>
          )
        },
        {
          path: 'net-worth/loans',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Loan />
            </Suspense>
          )
        },

        {
          path: 'net-worth/financial-expert',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <FinanceExpert />
            </Suspense>
          )
        },
        {
          path: 'net-worth/debt-asset-ratio',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <DebtAssetRation />
            </Suspense>
          )
        },
        {
          path: 'net-worth/insurance',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Insurance />
            </Suspense>
          )
        },
        {
          path: 'net-worth/insurance/term-life-insurance',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <TermLifeInsurance />
            </Suspense>
          )
        },
        {
          path: 'net-worth/insurance/term-life-insurance/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <TermLifeInsurance />
            </Suspense>
          )
        },
        {
          path: 'net-worth/insurance/umbrella-insurance',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <UmbrellaInsurance />
            </Suspense>
          )
        },
        {
          path: 'net-worth/insurance/umbrella-insurance/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <UmbrellaInsurance />
            </Suspense>
          )
        },
        {
          path: 'net-worth/insurance/whole-life-insurance',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <WholeLifeInsurance />
            </Suspense>
          )
        },
        {
          path: 'net-worth/insurance/whole-life-insurance/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <WholeLifeInsurance />
            </Suspense>
          )
        },
        {
          path: 'net-worth/insurance/long-term-insurance',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <LongTermInsurance />
            </Suspense>
          )
        },
        {
          path: 'net-worth/insurance/long-term-insurance/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <LongTermInsurance />
            </Suspense>
          )
        },

        {
          path: 'net-worth/insurance/auto-insurance',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AutoInsurance />
            </Suspense>
          )
        },
        {
          path: 'net-worth/insurance/auto-insurance/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AutoInsurance />
            </Suspense>
          )
        },
        {
          path: 'net-worth/insurance/individual-disability-insurance/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <DisabilityInsurance />
            </Suspense>
          )
        },
        {
          path: 'net-worth/insurance/individual-disability-insurance',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <DisabilityInsurance />
            </Suspense>
          )
        },
        {
          path: 'net-worth/estate-planning',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <EstatePlanning />
            </Suspense>
          )
        },
        {
          path: 'net-worth/power-of-attorney',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <EstatePowerOfAttorney />
            </Suspense>
          )
        },
        {
          path: 'net-worth/power-of-attorney/add',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AddPowerOfAttorney />
            </Suspense>
          )
        },
        {
          path: 'net-worth/power-of-attorney/edit/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AddPowerOfAttorney />
            </Suspense>
          )
        },
        {
          path: 'net-worth/hipaa-release',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <EstateHippaRelease />
            </Suspense>
          )
        },
        {
          path: 'net-worth/hipaa-release/add',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AddHippaRelease />
            </Suspense>
          )
        },
        {
          path: 'net-worth/hippa-release/edit/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AddHippaRelease />
            </Suspense>
          )
        },
        {
          path: 'net-worth/living-will-health-care-proxy',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <EstateLivingWill />
            </Suspense>
          )
        },
        {
          path: 'net-worth/living-will-health-care-proxy/add',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AddLivingWill />
            </Suspense>
          )
        },
        {
          path: 'net-worth/living-will-health-care-proxy/edit/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AddLivingWill />
            </Suspense>
          )
        },
        {
          path: 'net-worth/last-will-and-testament',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <EstateLastWill />
            </Suspense>
          )
        },
        {
          path: 'net-worth/last-will-and-testament/add',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AddLastWill />
            </Suspense>
          )
        },
        {
          path: 'net-worth/last-will-and-testament/edit/:id',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <AddLastWill />
            </Suspense>
          )
        },
        // send money
        {
          path: 'dashboard/send-money',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <SendMoney />
            </Suspense>
          )
        },
        {
          path: 'dashboard/send-money/ach-transfer',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <ACHTransfer />
            </Suspense>
          )
        },
        {
          path: 'dashboard/send-money/payee-transfer',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <PayeeTransfer />
            </Suspense>
          )
        },
        {
          path: 'dashboard/send-money/connected-account-transfer',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <ConnecAccountTransfer />
            </Suspense>
          )
        },
        {
          path: 'dashboard/balance-history',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <BalanceHistory />
            </Suspense>
          )
        }
      ]
    },
    {
      path: '/net-banking',
      element: isSignedIn ? <Navigate to={'/'} /> : <AuthLayout />,
      children: [
        {
          path: 'login',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Login />
            </Suspense>
          )
        },
        process.env.REACT_APP_MODE != 'Live' && {
          path: 'sign-up',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <SignUp />
            </Suspense>
          )
        },
        {
          path: 'forgot-password',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <ForgotPassWord />
            </Suspense>
          )
        },
        {
          path: ':type/otp',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Otp />
            </Suspense>
          )
        },
        {
          path: 'two-factor-authentication',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <TwoStepAuthentication />
            </Suspense>
          )
        },
        {
          path: 'google-authentication',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <MfaSetUp />
            </Suspense>
          )
        },
        {
          path: 'verify-google-authentication',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <MfaOtpScreen />
            </Suspense>
          )
        },
        {
          path: 'verify-email-authentication',
          element: (
            <Suspense fallback={<LazyLoading />}>
              <EmailOtpScreen />
            </Suspense>
          )
        }
      ]
    },
    {
      path: '/hippa-release-and-authorization/:id',
      element: (
        <Suspense fallback={<LazyLoading />}>
          <HippaRelease />
        </Suspense>
      )
    },
    {
      path: '/download-app',
      element: (
        <Suspense fallback={<LazyLoading />}>
          <DownloadApp />
        </Suspense>
      )
    },
    {
      path: '/reset-password/:type/:id',
      element: (
        <Suspense fallback={<LazyLoading />}>
          <ResetPassword />
        </Suspense>
      )
    },
    {
      path: '/living-will-health-care-proxy/:id',
      element: (
        <Suspense fallback={<LazyLoading />}>
          <LivingWill />
        </Suspense>
      )
    },
    {
      path: '/power-of-attorney/:id',
      element: (
        <Suspense fallback={<LazyLoading />}>
          <PowerOfAttorney />{' '}
        </Suspense>
      )
    },
    {
      path: '/last-will-and-testament/:id',
      element: (
        <Suspense fallback={<LazyLoading />}>
          <LastWill />
        </Suspense>
      )
    },
    {
      path: '/atm-locator',
      element: (
        <Suspense fallback={<LazyLoading />}>
          <AtmLocator />
        </Suspense>
      )
    },
    {
      path: '/download-bank-letter/:id',
      element: (
        <Suspense fallback={<LazyLoading />}>
          <DownloadBankLetter />
        </Suspense>
      )
    },
    {
      path: '/net-banking/download-bank-statement/:id',
      element: (
        <Suspense fallback={<LazyLoading />}>
          <DownloadBankStatement />
        </Suspense>
      )
    }
  ]);

  return routes;
}
