import React from 'react';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import SVButton from 'component/SVButton';

export default function SVDialog(props) {
  const { handleClose, handleAgree, title, show, Children, btnTitle,cancelBtnText } = props;
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header style={{ color: 'black' }} className="border-secondary">
        <Modal.Title className="textLinearBackground fw-bold">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ color: 'black' }}>
        <div>{Children}</div>
        <div className="row mt-5">
          <div className="col-lg-6 col-sm-6  mt-2">
            <SVButton
              title={cancelBtnText?cancelBtnText: "Cancel"}
              className="border border-black text-white border-2 bg-transparent "
              onClick={handleClose}
            />
          </div>
          <div className="col-lg-6 col-sm-6 mt-2">
            <SVButton className='fw-bold' title={btnTitle} onClick={handleAgree} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
